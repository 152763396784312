import React from 'react';
import {Text, StyleSheet, TouchableOpacity} from 'react-native'
import { colors } from '../constants';

function Button({title, onPress}) {
  
  return (
      <TouchableOpacity style={styles.button} onPress={onPress}>
          <Text style={styles.buttonLabel}>{title}</Text>
      </TouchableOpacity>
  );
}
const styles = StyleSheet.create({
  button:{
    marginTop: 10,
    marginBottom: 30,
    width: '50%',
    paddingVertical: 15,
    borderRadius: 8,
    textAlign: 'center',
    backgroundColor: 'rgba(255,255,255,0.8)',
    
  },
  buttonLabel:{
    color: colors.accent,
    textAlign: 'center',
    fontSize: 15,
    fontWeight: 'bold',
  }
});
export default Button;

import React, { useState, useEffect } from 'react';
import {View, StyleSheet} from 'react-native'
// import * as Haptics from 'expo-haptics';
import { GRID, GRID_SIZE, colors } from './constants';
import Tile from './components/Tile';
import Score from './components/Score';
import Button from './components/Button';

function App() {
  const [max, setMax]           = useState(10);
  const [tiles, setTiles]       = useState([]);
  const [removed, setRemoved]   = useState([]);
  const [score, setScore]       = useState(0);
  const [duration, setDuration] = useState(0);
  const [selected, setSelected] = useState([]);
  const [animated, setAnimated] = useState([]);
  const [final, setFinal]       = useState(-1);
  
  useEffect(() => {
    restart();
    setInterval(() => {
      setDuration((duration) => duration + 1);
    }, 1000);
  }, []);
  
  useEffect(() => {
    if(selected.length < 2) return;
    let sum = selected.reduce((acc, cur) => acc + tiles[cur], 0);
    // if sum of selected tiles is equal to size, then remove tiles and reeset selected
    if(sum % 10 === 0 && sum <= max) {
      let selectedItems = [...selected];
      let last = selectedItems.pop();
      
      // remove last index from selected
      setSelected([...selectedItems]);

      // update last clicked tile as sum of selected tiles
      let newTiles = [...tiles];
      newTiles[last] = sum;
      setTiles(newTiles);
      
      setMax(Math.max(...newTiles) + 10);
      
      setRemoved([...selectedItems]);
      setScore(score => score + sum);
    }
    // if sum of all selected tiles is grater than to size, then reset selected tiles
    if(sum > max)
      setSelected([]);

  }, [selected, tiles]);

  const randomTiles = () => {
    const newTiles = [];
    for(let i = 0; i < GRID*GRID; i++) {
      newTiles.push(randomNumber());
    }
    return newTiles;
  }

  const restart = () => {
    // reset tiles
    setTiles(randomTiles());
    setScore(0);
    setRemoved([]);
    setAnimated([]);
    setSelected([]);
    setDuration(0);
    setFinal(-1);
    setMax(10);
  }

  const randomNumber = () => {
    return Math.floor(Math.random() * (max-1)) + 1;
  }

  const onClick = (index) => {
    if(canBeSelected(index)) {
      setSelected(selected => [...selected, index]);
    }
    else setSelected([index]);
  }

  useEffect(() => {
    setSelected([]);
    let animateIndex = [];
    if(removed.length > 0) {
      let removedIndexes = [...removed];
      removedIndexes.sort();
      removedIndexes.reverse();
      let oldTiles = [...tiles];
      for(let i = 0; i < removedIndexes.length; i++) {
        let column = removedIndexes[i]%GRID;
        for(let j=0; j<= GRID; j++){
          if(removedIndexes[i] < column + j*GRID) {
            oldTiles[column + (j-1)*GRID] = oldTiles[column + j*GRID] ?? randomNumber();
            animateIndex.push(column + (j-1)*GRID);
          }
        }
      }
      setTiles([...oldTiles]);
      setRemoved([]);
      setAnimated([...animateIndex]);
    }
  } , [removed, tiles]);

  const canBeSelected = (index) => {
    if(selected.length === 0){
      setAnimated([]);
      return true;
    }

    if(selected.length === 1) {
      // next index can be just right, left , up or down of selected index
      if(selected[0] + 1 === index || selected[0] - 1 === index || selected[0] + GRID === index || selected[0] - GRID === index) return true;
    }
    if(selected.length >= 2) {
      // get the direction of the selected tiles
      let direction = Math.abs(selected[1] - selected[0]);
      // direction 1 is horizontal and direction 10 is vertical
      if(direction === 1){
        // next index can be just right, left of selected index
        if(Math.min(...selected) - 1 === index || Math.max(...selected) + 1 === index) return true;
      }
      if(direction === 10){
        // next index can be just up, down of selected index
        if(Math.min(...selected) - 10 === index || Math.max(...selected) + 10 === index) return true;
      }
    }
    return false;
  }

  const listItems = tiles.map((number, index) =>
    <Tile 
      removed={removed}
      key={index} 
      index={index} 
      number={number} 
      selected={selected}
      onClick={onClick}
      animated={animated}
      />
  );
  return (
    <View style={styles.container}>
      <Button title="Resart" onPress={restart} />
      <Score score={score} duration={duration} />
      <View style={styles.grid}>
        {listItems}
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  container:{ 
    flex: 1,
    backgroundColor: colors.accent,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  grid:{
    flex: -1,
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: GRID_SIZE,
    height: GRID_SIZE,
  },
  button:{
    marginTop: 10,
    marginBottom: 30,
    width: '50%',
    paddingVertical: 15,
    borderRadius: 8,
    textAlign: 'center',
    backgroundColor: 'rgba(255,255,255,0.5)',
    
  },
  buttonLabel:{
    color: colors.primary,
    textAlign: 'center',
    fontSize: 15,
    fontWeight: 'bold',
  }
});
export default App;

import React from "react";
import {View, Text, StyleSheet, TouchableOpacity} from 'react-native'
import { TILE_SIZE, colors } from '../constants';

const Tile = ({ removed, selected, animated, index, number, onClick }) => {

    const handelClick = () => {
      onClick(index);
    }

    const animate = () => {
      if(selected.includes(index)) {
        return {
          opacity: 0.5,
          transition: { duration: 0.2 }
        }
      }
      if(removed.includes(index)) {
        return {
          opacity: 0.1,
          transition: { duration: 1 }
        }
      }
      // if(animated.includes(index)) 
      //   return { y: 0, transition: { duration: 0.5 }};
    }
    const initial = () => {
        // if(animated.includes(index)) 
        //   return { y: 51};
    }

    return (
      <View style={styles.container}>
        <TouchableOpacity
          style={[styles.tile, selected.includes(index) ? styles.selected : null]}
          onPress={handelClick}>
            <Text style={styles.number}>{number}</Text>
        </TouchableOpacity>
      </View>
    );
}
const styles = StyleSheet.create({
    container:{
        flex: -1,
        width: TILE_SIZE,
        height: TILE_SIZE,
        
    },
    tile:{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        borderColor: colors.accent,
        backgroundColor: colors.primary,
        borderRadius: 16,
        margin: 1,
        borderBottomColor: '#953131',
        borderRightColor: '#953131',
        borderBottomWidth: 4,
        borderRightWidth: 4,
    },
    number:{
        fontSize: 25,
        color: colors.accent,
    },
    selected:{
        backgroundColor: 'rgba(0,0,0, 0.8)',
        borderBottomWidth: 4,
        borderRightWidth: 2,
      }
});
export default Tile;

import React from 'react';
import {View, Text, StyleSheet} from 'react-native'
import { GRID_SIZE, colors } from '../constants';

function Score({ score, duration}) {

  const secondsToHms = () => {
      let d = Number(duration);
      let h = Math.floor(d / 3600);
      let m = Math.floor(d % 3600 / 60);
      let s = Math.floor(d % 3600 % 60);
      return `${h.toString().padStart(2,0)}:${m.toString().padStart(2,0)}:${s.toString().padStart(2,0)}`; 
  }

  return (
      <View style={styles.scores}>
        <View style={styles.scoreItem}>
          <Text style={styles.scoreLavel}>SCORE</Text>
          <Text style={styles.scoreValue}>{score}</Text>
        </View>
        <View style={styles.scoreItem}>
          <Text style={styles.scoreLavel}>TIME</Text>
          <Text style={styles.scoreValue}>{secondsToHms()}</Text>
        </View>
      </View>
  );
}
const styles = StyleSheet.create({
  
  scores:{ 
    flex: -1, 
    flexDirection: 'row', 
    justifyContent: 'space-around',
    width: GRID_SIZE,
    paddingVertical: 10,
  },
  scoreItem:{ 
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.2)',
    paddingHorizontal: 20,
    paddingVertical: 10,
    borderRadius: 8,
  },
  scoreLavel:{ 
    fontSize: 14,
    color: colors.primary,
    fontWeight: 'bold',
  },
  scoreValue:{
    fontSize: 24,
    color: colors.primary,
    fontWeight: 'bold',
  },
});

export default Score;

import {Dimensions} from 'react-native'

const GRID = 8;
const GRID_SIZE = Math.min(Dimensions.get('window').width, Dimensions.get('window').height);
const TILE_SIZE = GRID_SIZE / GRID;

const colors = {
    primary: '#F5C9C9',
    accent: '#D94F4F',
}
export {
    GRID,
    GRID_SIZE,
    TILE_SIZE,
    colors,

}